import * as React from "react";
import { Link, PageProps } from "gatsby";
import * as styles from "./styles.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Breadcrumb } from "../../../components/breadcrumb/breadcrumb";
import Layout from "../../../components/layout/layout";
import Container from "../../../components/container/container";
import Heading from "../../../components/basic-components/heading/heading";
import { ProductBar } from "../../../components/product-bar/product-bar";
import { Level } from "react-accessible-headings";
import scrollTo from "gatsby-plugin-smoothscroll";
import { Helmet } from "react-helmet";
import blogImage from "../../../images/cozum/bogaz-agrisi-nedenleri/woman-having-a-sore-throat-sitting-on-the-floor-wi-2021-10-18-21-34-48-utc.webp";
import { useEffect } from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const CoughPage: React.FC<PageProps> = ({ location }: PageProps) => {
  useEffect(() => {
    if (location.hash) {
      scrollTo(location.hash);
    }
  }, []);
  return (
    <Layout
      stickyHeader={false}
      seoConfig={{
        title: "Boğaz Ağrısı Nedenleri Nelerdir?",
        description:
          "Boğaz ağrısı nedenleri nelerdir? Boğaz ağrısı belirtileri ve daha fazlası için sayfamızı ziyaret edin!",
      }}
    >
      <Helmet>
        <script type="application/ld+json">
          {`
            {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {"@type": "ListItem","position": 1,"name": "Ana Sayfa","item": "https://www.bisolnatur.com.tr/"},
                  {"@type": "ListItem","position": 2,"name": "Öksürük","item": "https://www.bisolnatur.com.tr/oksuruk/"},
                  {"@type": "ListItem","position": 3,"name": "Boğaz Ağrısı Nedenleri Nelerdir?","item": "${location.href}"}
                ]
            }
          `}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${location.href}"
              },
              "headline": "Öksürük Nedir?",
              "image": "${location.origin + blogImage}",  
              "author": {
                "@type": "Organization",
                "name": "Boğaz Ağrısı Nedenleri Nelerdir?",
                "url": "${location.origin}"
              },  
              "publisher": {
                "@type": "Organization",
                "name": "Bisolnatur",
                "logo": {
                  "@type": "ImageObject",
                  "url": "${location.origin}/logo.svg"
                }
              },
              "datePublished": "2021-11-10",
              "dateModified": "2021-11-10"
            }
          `}
        </script>
        <script type="application/ld+json">{`{"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"Boğaz Ağrısı Belirtileri Nelerdir?","acceptedAnswer":{"@type":"Answer","text":"Burun ve ağzın arkasındaki yemek ve soluk borusunun başladığı bölge farinks olarak isimlendirilir. Bu bölgede çeşitli nedenlerle inflamasyon meydana gelmesi ise farenjit olarak tanımlanır. Farenjit gelişimi ile birlikte boğaz bölgesi, kaşıntılı, kuru, hassas ve tahriş olmuş gibi hissedilebilir."}},{"@type":"Question","name":"Boğaz Ağrısı Nedenleri Nelerdir?","acceptedAnswer":{"@type":"Answer","text":"Boğaz ağrısı nedenleri, travmadan enfeksiyonlara kadar geniş bir yelpazede incelenir. Solunum yollarını hedef alan bakteri ve virüsler, alerjiler, tütün kullanımı veya dumanına maruz kalınması ve hava kuruluğu gibi durumlar, boğaz ağrısı nedenleri içerisinde yer alır. Bu nedenlere ek olarak bazı kişilerde reflü varlığında veya yüksek sesle konuşma sonrasında da farenks bölgesinde inflamasyona bağlı boğaz ağrısı şikayeti oluşabilir."}}]}`}</script>
      </Helmet>
      <Breadcrumb
        pathname={location.pathname}
        noBg={true}
        className={styles.breadcrumb}
      />
      <Container fullWidth>
        <div className={classNames(styles.header)}>
          <StaticImage
            className={styles.image}
            src="../../../images/cozum/bogaz-agrisi-nedenleri/Content-image.webp"
            alt="Boğaz ağrısı yaşayan görseli"
            width={1680}
            height={450}
          />
          <Container>
            <Heading>Boğaz Ağrısı Nedenleri Nelerdir?</Heading>
            <ul>
              <li>
                <AnchorLink
                  className="blue"
                  to="#bogaz-agrisi-nedir"
                  title="Boğaz Ağrısı Nedir?"
                >
                  Boğaz Ağrısı Nedir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#bogaz-agrisi-belirtileri-nelerdir"
                  title="Boğaz Ağrısı Belirtileri Nelerdir?"
                >
                  Boğaz Ağrısı Belirtileri Nelerdir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#bogaz-agrisi-nedenleri-nelerdir"
                  title="Boğaz Ağrısı Nedenleri Nelerdir?"
                >
                  Boğaz Ağrısı Nedenleri Nelerdir?
                </AnchorLink>
              </li>
            </ul>
          </Container>
        </div>
      </Container>
      <Container>
        <div className={styles.holder}>
          <p id="bogaz-agrisi-nedir" className="extra-bold">
            Boğaz ağrısı, her yaştan kişiyi etkileyebilen bir durumdur. Genel
            olarak virüs ve bakterilere bağlı oluşan boğaz ağrısı, alerji,
            mantar enfeksiyonu, reflü ve hava kirliliği gibi nedenlerle de
            meydana gelebileceği için bilinçli olunması önerilir.
          </p>
          <section className={styles.whatHolder}>
            <Level>
              <h2 className="orange underline">Boğaz Ağrısı Nedir? </h2>
              <p>
                Boğaz ağrısı, bu bölgede özellikle yutkunma sırasında kötüleşen
                ağrı, tahriş ve kaşıntı gibi rahatsız edici duyguların varlığını
                ifade eder. Boğaz ağrısının meydana gelmesinde en sık olarak
                nezle ve grip gibi viral hastalıklar yer alır. Bu şekilde
                virüslere bağlı oluşan boğaz ağrısı, genellikle kendiliğinden
                gerileme eğilimindedir. Virüsler dışında streptococcus türü
                bakteriler de bu bölgeyi enfekte ederek boğaz ağrısı gelişimine
                neden olabilir. Virüslere bağlı oluşan boğaz ağrısı şikayetinden
                farklı olarak altta yatan nedenin bakteriyel enfeksiyon olması
                halinde tedavide antibiyotik ilaçların reçetelendirilmesine
                başvurulabilir. Boğaz ağrısı nedenleri genellikle kendiliğinden
                veya çeşitli ilaçlar kullanılarak tedavi edilebilen durumlardır.
                Ancak bazı boğaz ağrısı nedenleri, zaman içerisinde nefes alıp
                vermeyi zorlaştırıcı bir seyir izleyebileceği için bilinçli
                olunmalıdır.
              </p>
              <Level>
                <Heading id="bogaz-agrisi-belirtileri-nelerdir">
                  Boğaz Ağrısı Belirtileri Nelerdir?
                </Heading>
              </Level>
              <div className={classNames("flexbox", styles.rightCoughHolder)}>
                <div>
                  <p>
                    Burun ve ağzın arkasındaki yemek ve soluk borusunun
                    başladığı bölge farinks olarak isimlendirilir. Bu bölgede
                    çeşitli nedenlerle inflamasyon meydana gelmesi ise farenjit
                    olarak tanımlanır. Farenjit gelişimi ile birlikte boğaz
                    bölgesi, kaşıntılı, kuru, hassas ve tahriş olmuş gibi
                    hissedilebilir.
                    <br />
                    <br />
                    Boğaz ağrısına eşlik eden belirtiler, bu durumun oluşumunda
                    rol oynayan asıl nedene bağlı olarak değişkenlik
                    gösterebilir. Genel olarak ise boğaz ağrısına eşlik eden
                    belirtiler şu şekilde özetlenebilir:
                  </p>
                </div>
                <div>
                  <StaticImage
                    className={styles.image}
                    src="../../../images/cozum/bogaz-agrisi-nedenleri/woman-having-sore-throat-tonsillitis-suffering-f-2021-08-30-04-05-48-utc.webp"
                    alt="Muayene görseli"
                    width={384}
                    height={640}
                  />
                </div>
                <div className={styles.breakme} />
                <div className={styles.listHolder}>
                  <ul>
                    <li className="blue">
                      Boğazda ağrı ve yanma hissi oluşması,
                    </li>
                    <li className="blue">
                      Konuşma veya yutkunma sırasında ağrının kötüleşmesi,
                    </li>
                    <li className="blue">Yutkunma güçlüğü,</li>
                    <li className="blue">
                      Çene ve boyun bölgesinde yer alan bezlerin büyümesi ve
                      ağrıması,
                    </li>
                    <li className="blue">
                      Bademciklerin şişmesi ve kızarık bir görünüm alması,
                    </li>
                    <li className="blue">
                      Bademcikler üzerinde beyaz lekelenmeler oluşması,
                    </li>
                    <li className="blue">
                      Seste kabalaşma veya ses kısıklığı.
                    </li>
                  </ul>
                </div>
              </div>
              <p>
                Boğaz ağrısının enfeksiyon hastalıklarına bağlı olarak oluşması
                halinde diğer çeşitli belirtiler de ortaya çıkabilir:
              </p>
              <ul>
                <li className="blue">Ateş,</li>
                <li className="blue">Üşüme, titreme,</li>
                <li className="blue">Öksürük,</li>
                <li className="blue">Burun akıntısı,</li>
                <li className="blue">Hapşırma,</li>
                <li className="blue">
                  Vücudun çeşitli bölgelerinde ağrı oluşması,
                </li>
                <li className="blue">Baş ağrısı,</li>
                <li className="blue">Bulantı ve kusma,</li>
                <li className="blue">İştah kaybı.</li>
              </ul>
              <p>
                Boğaz ağrısı her yaşta sık görülen bir şikayettir ancak
                özellikle 5-15 yaş arasındaki çocuklarda sık olarak rastlanılır.
                Bu yaş grubunun bir diğer özelliği de yetişkinlere göre
                bakteriyel kaynaklı boğaz ağrısı gelişiminin daha sık olmasıdır.
                Çocuklarda boğaz ağrısına eşlik eden belirtiler, yaşa göre
                değişiklik gösterebilir. Oluşabilecek belirtiler genel olarak şu
                şekildedir:
              </p>
              <ul>
                <li className="blue">Yüksek ateş,</li>
                <li className="blue">
                  Boyun bölgesinde lenf nodlarının hassaslaşması ve ödemlenmesi,
                </li>
                <li className="blue">Huzursuzluk,</li>
                <li className="blue">İştahın azalması,</li>
                <li className="blue">
                  Bademciklerin üzerinin beyaz iltihap ile kaplanması,
                </li>
                <li className="blue">
                  Özellikle üst damakta belirgin olan kırmızı lekelenmeler
                  oluşması,
                </li>
                <li className="blue">Baş ağrısı,</li>
                <li className="blue">Karın ağrısı.</li>
              </ul>
              <p>
                3 yaşından küçük çocuklarda bakterilere bağlı oluşan boğaz
                ağrısına nadir olarak rastlanılır ve meydana gelse bile oluşan
                belirtiler daha hafif seyretme eğilimindedir. Hastalığın seyri
                daha iyi bile olsa gelecekte bakteriyel enfeksiyona bağlı
                oluşabilecek istenmeyen durumların önlenmesi adına hekim
                tarafından antibiyotik ilaçların reçetelendirilmesine
                başvurulabilir. Genel olarak çocuklarda viral enfeksiyonlar,
                boğaz ağrısı gelişiminin en sık nedenini oluşturur. Viral
                kaynaklı boğaz ağrılarında antibiyotik ilaçların kullanımı
                gereksiz olup bunun yerine şikayetleri hafifletici uygulamalara
                başvurulması önerilir.
              </p>
              <StaticImage
                className={styles.image}
                src="../../../images/cozum/bogaz-agrisi-nedenleri/woman-having-a-sore-throat-sitting-on-the-floor-wi-2021-10-18-21-34-48-utc.webp"
                alt="Hapşuran çocuk görseli"
                width={792}
                height={430}
              />
              <p>
                Boğaz ağrısı ve oluşan diğer şikayetler, genellikle 5-10 gün
                içerisinde kendiliğinden gerileme eğiliminde olan semptomlardır.
                Ancak bazı durumlarda tıbbi müdahale gereksinimi söz konusu
                olabileceği için bilinçli olunmalıdır. Dolayısıyla aşağıda yer
                alan yakınmalara dikkat edilmelidir:
              </p>
              <ul>
                <li className="blue">
                  Boğaz ağrısı şikayetinde gerileme olmaması veya seyrinin
                  kötüleşmesi,
                </li>
                <li className="blue">Nefes almakta zorlanmak,</li>
                <li className="blue">
                  Yüz ve boyun bölgesinde ödem gelişmesi,
                </li>
                <li className="blue">38,5 derecenin üzerinde ateş oluşması,</li>
                <li className="blue">
                  Burun akıntısı veya balgamda kan varlığı,
                </li>
                <li className="blue">Boyunda şişlik gelişmesi,</li>
                <li className="blue">
                  Ses kısıklığının 2 hafta boyunca devam etmesi,
                </li>
                <li className="blue">Kulak ağrısı oluşması,</li>
                <li className="blue">Deri döküntülerinin gelişmesi,</li>
                <li className="blue">Eklem ağrılarının hissedilmesi,</li>
                <li className="blue">Ağzın açılmasında zorluk yaşanması,</li>
              </ul>
              <StaticImage
                className={styles.image}
                src="../../../images/cozum/bogaz-agrisi-nedenleri/cloud-co2-symbol-on-blue-sky-background-planet-po-2021-08-29-07-10-43-utc.webp"
                alt="Hapşuran çocuk görseli"
                width={792}
                height={430}
              />
            </Level>
          </section>
          <section id="bogaz-agrisi-nedenleri-nelerdir" className={styles.typesHolder}>
            <Level>
              <Level>
                <Heading>Boğaz Ağrısı Nedenleri Nelerdir? </Heading>
              </Level>
              <p>
                Boğaz ağrısı nedenleri, travmadan enfeksiyonlara kadar geniş bir
                yelpazede incelenir. Solunum yollarını hedef alan bakteri ve
                virüsler, alerjiler, tütün kullanımı veya dumanına maruz
                kalınması ve hava kuruluğu gibi durumlar, boğaz ağrısı nedenleri
                içerisinde yer alır. Bu nedenlere ek olarak bazı kişilerde reflü
                varlığında veya yüksek sesle konuşma sonrasında da farenks
                bölgesinde inflamasyona bağlı boğaz ağrısı şikayeti oluşabilir.
                <br />
                <br />
                Her ne kadar boğaz ağrısı herkeste ortaya çıkabilecek bir durum
                olsa da çeşitli risk faktörlerinin varlığı halinde bireyler bu
                şikayetin gelişmesine daha yatkın olabilirler:
              </p>
              <ul>
                <li className="blue">
                  Küçük çocuklar ve ergenler boğaz ağrısı şikayeti açısından
                  riskli yaş grubunu oluşturur. Yaşı üç ile on beş arasında
                  değişen çocuklar, özellikle bakteriyel enfeksiyonlara bağlı
                  boğaz ağrısı şikayetinin en fazla görüldüğü yaş grubudur.
                </li>
                <li className="blue">
                  Tütün kullanımı veya pasif içicilik, boğaz bölgesinin tahriş
                  olmasına neden olabilir.
                </li>
                <li className="blue">
                  Mevsimsel alerjisi olan kişiler veya sürekli olarak toz, küf
                  veya hayvansal kimyasallara karşı alerjik reaksiyon gelişen
                  kişilerde boğaz ağrısı şikayetinin ortaya çıkma riskinde bir
                  artış söz konusudur.
                </li>
                <li className="blue">
                  Bazı bölgelerde endüstriyel yapılar daha yoğun olarak
                  bulunabilir. Fabrika dumanlarında yer alan kimyasallara
                  maruziyet veya katı yakıt tüketilen bir çevrede yaşamak,
                  kişileri boğaz ağrısı şikayeti yaşamaya yatkın hale
                  getirebilir.
                </li>
                <li className="blue">
                  Sinüsler baş bölgesinde bulunan havanın dolaştığı boşluklu
                  yapılardır. Bu yapılar içerisinde meydana gelen
                  iltihaplanmalarda salgılanan sıvılar, boğaza akarak tahriş ve
                  ağrı gibi şikayetlerin oluşmasına neden olabilir.
                </li>
                <li className="blue">
                  Kapalı ortamlarda kalabalık gruplar halinde yaşayan kişilerde
                  boğaz ağrısına neden olan çeşitli mikropların yayılması daha
                  kolaydır. Kreşler, okullar, iş yerleri ve uçak gibi kapalı
                  alanlar, virüs ve bakteri kaynaklı boğaz ağrısı mikroplarının
                  kişiler arasında kolayca yayılabileceği ortamlara örnek teşkil
                  ederler.
                </li>
              </ul>
              <p>
                Boğaz ağrısı şikayeti bulunan birçok kişi, genellikle bu
                şikayetin altında yatan nedeni tahmin edebilir. Bu tahminin
                yapılamaması halinde ise eşlik eden diğer belirtiler göz önünde
                bulundurularak tanının koyulması sağlanabilir. Boğaz ağrısı
                nedenleri arasında en sık karşılaşılan durumlar şu şekilde
                özetlenebilir:
              </p>
              <Level>
                <Heading>Viral Enfeksiyonlar </Heading>
              </Level>
              <p>
                Virüslere bağlı oluşan üst solunum yolu rahatsızlıkları, boğaz
                ağrısı şikayeti bulunan kişilerin neredeyse %90’ında sebep
                olarak tespit edilir. Soğuk algınlığına neden olabilen 200’den
                fazla virüs türü bulunur. Adenovirüs, rhinovirüs ve coronavirüs,
                boğaz ağrısına neden olabilecek virüslere örnek teşkil ederler.
                Boğaz ağrısının viral bir enfeksiyona bağlı oluşması halinde
                genellikle burun tıkanıklığı, burun akıntısı, hapşırma, baş
                ağrısı ve ateş gibi diğer şikayetler de belirtiler arasına
                eklenebilir. Virüslere bağlı oluşan boğaz ağrısında aynı zamanda
                bademcikler üzerinde iltihaplanma da meydana gelebilir.
                <br />
                <br />
                Boğaz bölgesinde iltihaplanma ve dolayısıyla ağrı şikayetine
                neden olabilecek diğer virüsler şu şekildedir:
              </p>
              <ul>
                <li className="blue">
                  İçerisinde influenza (grip) virüsünün de olduğu ortomiksovirüs
                  ailesi,
                </li>
                <li className="blue">
                  Enfeksiyöz mononükleoz hastalığı etkeni olan Epstein-Barr
                  virüsü,
                </li>
                <li className="blue">
                  Ağız ve boğazda ağrılı veziküllere neden olabilen koksaki ve
                  ekovirüs,
                </li>
                <li className="blue">Kızamık virüsü,</li>
                <li className="blue">
                  Boğazda yara oluşumuna neden olabilen Herpes simpleks virüsü.
                </li>
              </ul>
              <StaticImage
                className={styles.image}
                src="../../../images/cozum/bogaz-agrisi-nedenleri/viruses-and-red-blood-cells-viral-infection-di-2021-08-31-13-46-22-utc.webp"
                alt="Hapşuran çocuk görseli"
                width={792}
                height={430}
              />
              <p>
                Viral kaynaklı boğaz ağrısı şikayetinin şiddeti, etken virüsün
                türüne bağlı olarak değişmekle birlikte genellikle 1 hafta 10
                gün içerisinde gerileme gösterir. Virüslere bağlı oluşan boğaz
                ağrısında, eşlik eden diğer belirtilerin olması halinde sağlık
                kuruluşlarına başvurarak uzman hekimden destek alınması
                önerilir.
              </p>
              <Level>
                <Heading>Bakteriyel Enfeksiyonlar </Heading>
              </Level>
              <p>
                Çeşitli bakteriyel enfeksiyonlar, boğaz ağrısı şikayetinin
                gelişiminde rol oynayabilir. Bu türler arasında en sık
                karşılaşılanı, Streptococcus pyogenes olarak isimlendirilen
                mikroorganizmadır. Genç ve yetişkin bireylerde boğaz ağrısı
                vakalarının sadece %10’u bu bakteri kaynaklı ortaya çıkarken,
                boğaz ağrısı şikayeti olan küçük çocukların neredeyse üçte
                birinde etken mikroorganizma bu bakteridir. Bu yaş grubunda
                meydana gelen bakteriyel boğaz enfeksiyonları, romatizmal ateş
                veya böbrek iltihaplanması gibi ağır seyirli durumlarla ilişkili
                olması nedeniyle dikkatli olunmalıdır. Bakteriyel enfeksiyon
                kaynaklı boğaz ağrısı olan bireylerde hekim, antibiyotik
                ilaçların reçetelendirilmesine başvurabilir.
              </p>
              <StaticImage
                className={styles.image}
                src="../../../images/cozum/bogaz-agrisi-nedenleri/close-up-of-3d-microscopic-blue-bacteria-2021-08-26-22-28-53-utc.webp"
                alt="Hapşuran çocuk görseli"
                width={792}
                height={430}
              />
              <p>
                Streptococcus türü bakterilere ek olarak Neisseria gonorrhoeae
                ve klamidya gibi cinsel yolla bulaşan bakteriler de boğazda
                iltihaplanmaya neden olabilirler. Krup nedeni olan Bordetella
                pertussis, bir diğer boğaz ağrısı nedeni olan bakteridir.
                Bakterilere bağlı oluşan boğaz ağrısı olgularında çeşitli
                tetkikler ile hangi bakterinin bu şikayetin oluşmasında rol
                oynadığı tespit edilebilir.
              </p>
              <Level>
                <Heading>
                  Alerjik Kökenli Boğaz İltihabı ve Geniz Akıntısı{" "}
                </Heading>
              </Level>
              <p>
                Hayvan tüyleri, bitkilerden salınan polen ve çeşitli
                kimyasallar, bazı kişilerde bağışıklık sisteminde aşırı bir
                yanıta neden olarak alerjen özellik gösterebilir. Alerji
                gelişimi ile birlikte vücutta salınan kimyasallar ise burun
                tıkanıklığı, gözlerde sulanma, hapşırma ve boğazda tahriş gibi
                şikayetlerin gelişimine neden olabilir. Burun tıkanıklığı
                kişinin ağzından nefes alıp vermesini tetikler. Ağız solunumu
                ise boğaz bölgesindeki kuruluğu artırarak kaşıntı ve tahriş
                hissinin artması ile sonuçlanabilir.
                <br />
                <br />
                Bazı kişilerde burun içi hava yolları ve sinüslerden aşırı
                miktarda mukus salgılanması meydana gelebilir. Fazla düzeyde
                salgılanan mukus geniz akıntısı ile boğaz bölgesine inerek
                burada iltihaplanmaya neden olabilir. Bazı kişiler geniz
                akıntısını boğazlarına bir şey takılmış gibi hissedebilirler.
              </p>
              <Level>
                <Heading>Mantar Kaynaklı Enfeksiyonlar</Heading>
              </Level>
              <p>
                Boğaz ağrısına neden olan mantar kaynaklı enfeksiyonların
                başında, halk arasında pamukçuk olarak bilinen rahatsızlığın
                nedeni olan Candida albicans türü mantarlar rol oynar. Mantar
                enfeksiyonları özellikle bağışıklık sistemi tam olarak işlev
                gösteremeyen bireyleri etkiler. Ağız içerisinde kandida
                enfeksiyonu gelişmesi, genellikle herhangi bir şikayete neden
                olmasa da bazı kişilerde ağız, dil ve boğazda ağrı şeklinde de
                kendisini gösterebilir.
              </p>
              <Level>
                <Heading>Hava Kuruluğu ve Hava Kirliliği </Heading>
              </Level>
              <p>
                Hava kuruluğu, ağız içi ve boğaz bölgesinde nem kaybına neden
                olarak bu dokularda kuruluk meydana getirebilir. Nemini kaybeden
                dokularda tahrişe karşı bir yatkınlık oluşur ve zaman içerisinde
                boğaz ağrısı şikayeti ortaya çıkabilir. Hava kuruluğu dışında
                fabrika dumanı, çeşitli kimyasallar, tahriş edici maddeler
                nedeniyle oluşan hava kirliliği veya sadece tütün dumanına maruz
                kalınması bile boğaz bölgesinde tahrişe neden olarak boğaz
                ağrısı şikayeti gelişimi ile sonuçlanabilecek durumlar
                arasındadır.
              </p>
              <Level>
                <Heading>Boğazda Travma Meydana Gelmesi </Heading>
              </Level>
              <p>
                Direkt olarak boyun bölgesine alınan künt veya kesici travmalar,
                boğaz ağrısı şikayetine neden olabileceği gibi sert yapıdaki
                gıdalar tüketilirken bu besinlerin küçük parçalarının boğaza
                takılması halinde de boğaz ağrısı ortaya çıkabilir. Boğazda
                meydana gelebilen travmalar sadece bu örnekler ile sınırlı
                değildir. Ses tellerinin aşırı kullanıldığı meslek dallarında
                uzun süreli yüksek seste konuşma veya bağırma sonucunda da boğaz
                ağrısı şikayeti meydana gelebilir.
              </p>
              <Level>
                <Heading>Reflü Rahatsızlığı</Heading>
              </Level>
              <p>
                Reflü rahatsızlığı, mide içerisindeki asidin veya safranın önce
                yemek borusuna oradan da boğaz bölgesine ulaşması ile
                karakterize bir durumdur. Bu sindirim sıvıları, yemek borusu ve
                boğaz bölgesinin dokularında tahrişe neden olarak boğaz ağrısı
                şikayetinin gelişmesine neden olabilir. Reflü nedeniyle meydana
                gelen boğaz ağrısı özellikle uzun süreli yatma sonrasında ya da
                sabah uyanıldığında belirgin şekilde hissedilir.
                <br />
                <br />
                Boğaz ağrısı her ne kadar rahatsız edici bir durum olsa da
                genellikle ciddi bir seyir izlemez ve kısa sürede kendiliğinden
                gerileme eğilimindedir. Evde yapılacak çeşitli basit uygulamalar
                ile oluşan şikayetler genellikle kolayca kontrol altına
                alınabilir.
              </p>
            </Level>
          </section>
          <section className={styles.kaynakca}>
            <Level>
              <Level>
                <Heading id="kaynakca">Kaynakça</Heading>
              </Level>
            </Level>
            <p>
              1- https://www.mayoclinic.org/diseases-conditions/sore-throat/symptoms-causes/syc-20351635
            </p>
            <p>
              2- https://www.medicalnewstoday.com/articles/311449
            </p>
            <p>
              3- https://www.healthline.com/health/sore-throat
            </p>
            <p>
              4- https://www.verywellhealth.com/sore-throat-causes-and-risk-factors-1191878
            </p>
            <p>
              5-
              https://my.clevelandclinic.org/health/symptoms/8274-sore-throat-pharyngitis
            </p>
          </section>
        </div>
        <div className="date">
          <p>Yayınlama Tarihi: 2021-11-10</p>
          <p>Son güncellenme Tarihi: 2021-11-10</p>
        </div>
      </Container>
      <ProductBar />
    </Layout>
  );
};
export default CoughPage;
