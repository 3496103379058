// extracted by mini-css-extract-plugin
export var breadcrumb = "styles-module--breadcrumb--XlwUp";
export var breakme = "styles-module--breakme--yzXzk";
export var content = "styles-module--content--W5yML";
export var header = "styles-module--header--TKzgZ";
export var holder = "styles-module--holder--G3MYk";
export var howHolder = "styles-module--howHolder--GvBND";
export var image = "styles-module--image--N4nAg";
export var kaynakca = "styles-module--kaynakca--E8Hyh";
export var kidsHolder = "styles-module--kidsHolder--kwyEe";
export var listHolder = "styles-module--listHolder--QOz+P";
export var productHolder = "styles-module--productHolder--P5f9i";
export var rightCoughHolder = "styles-module--rightCoughHolder--t1ytE";
export var typesHolder = "styles-module--typesHolder--7KC8i";
export var whatHolder = "styles-module--whatHolder--Sf5Er";
export var whyHolder = "styles-module--whyHolder--1g2Kf";